<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 16 23.437"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7.007 1h2.03A6.011 6.011 0 0 1 15 7.037v8.313a7 7 0 1 1-14 0V7.037A6.077 6.077 0 0 1 7.007 1ZM8 4.937v3.937"
    />
  </svg>
</template>
